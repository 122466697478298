import type { iconNames } from '@lemonenergy/lemonpie'
import { Text, Link, Icon } from '@lemonenergy/lemonpie'
import type { TextVariantProp } from '@lemonenergy/lemonpie/dist/Text'
import type { MouseEventHandler } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

export interface LinkWithIconProps {
  name?: (typeof iconNames)[number]
  href?: string
  onClick?: MouseEventHandler
  bold?: boolean
  rotateIcon?: number
  className?: string
  variant?: TextVariantProp
  clickArea?: boolean
}

const StyledIcon = styled(Icon)<{ bold?: boolean; rotate?: number }>(
  ({ rotate = 0, theme: { spacing } }) => {
    const rotateStyles = rotate
      ? css`
          transform-origin: center;
          transform: rotate(${rotate}deg);
        `
      : ''

    return css`
      vertical-align: middle;
      transform-origin: center;
      margin-left: ${spacing(0.5)};

      svg {
        path {
          stroke-width: 1px;
          stroke: currentColor;
        }
      }
      ${rotateStyles}
    `
  },
)

const StyledLink = styled(Link)(
  ({ theme: { spacing, colors } }) => css`
    display: inline-flex;
    align-items: center;
    gap: ${spacing(0.5)};
    color: ${colors.primary.main};
    width: fit-content;
  `,
)

const LinkWithIcon = ({
  name,
  bold,
  children,
  rotateIcon,
  variant,
  clickArea,
  ...props
}: LinkWithIconProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <StyledLink {...props} clickArea={clickArea}>
      <Text forwardedAs="span" variant={variant ?? 'body200'} bold={bold}>
        {children}
      </Text>
      {name && (
        <StyledIcon rotate={rotateIcon} bold={bold} size={1.25} name={name} />
      )}
    </StyledLink>
  )
}

export default styled(LinkWithIcon)<LinkWithIconProps>``
